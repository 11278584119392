<template>
  <div style="height: 100%">
    <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <!-- <div ref="print" id="print" class="printList" >
      test
    </div> -->
    <vueEasyPrint sheetShow ref="easyPrint">
      <template>
        <printList
          :sheetData="sheetdata"
          :exportInfoBrief="exportinfo"
        ></printList>
      </template>
    </vueEasyPrint>

    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle; " class="search_condition_f">
        <div class="search_condition">
          <div class="pname">
            <label for="txtContractNumber" class="control-label"
              >搜索内容</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              placeholder="商品名称/订单编号"
              v-model.trim="searchpname"
              @keyup.enter="search"
            />
          </div>

          <div class="bdate">
            <label for="txtBDate" class="control-label">下单日期从</label>
          </div>
          <div class="bdate_value">
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event, 'b')"
              v-model.trim="searchbdate"
            />
          </div>
          <div>
            <select name="sltBHour" class="bdate_value_time" v-model="sBHour">
              <option
                v-for="(item, index) in listhours"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltBMinute"
              class="bdate_value_time"
              v-model="sBMinute"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltBSecond"
              class="bdate_value_time"
              v-model="sBSecond"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="edate">
            <label for="txtEDate" class="control-label">截止到</label>
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event, 'e')"
              v-model.trim="searchedate"
            />
          </div>
          <div>
            <select name="sltEHour" class="bdate_value_time" v-model="sEHour">
              <option
                v-for="(item, index) in listhours"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltEMinute"
              class="bdate_value_time"
              v-model="sEMinute"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltESecond"
              class="bdate_value_time"
              v-model="sESecond"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="sltStyle">
            <select name="sltPayStatus" v-model="orderStatus">
              <option value="">订单状态</option>
              <option value="0">待付款</option>
              <option value="1">待发货</option>
              <option value="2">待收货</option>
              <option value="3">待评论</option>
              <option value="4">退货</option>
              <option value="5">已完成</option>
              <option value="6">已关闭</option>
            </select>
          </div>
          <div class="edate">
            <label class="control-label">排除</label>
          </div>
          <div class="sltStyle">
            
            <select name="sltPayStatus" v-model="orderStatusExcept">
              <option value="">订单状态</option>
              <option value="0">待付款</option>
              <option value="1">待发货</option>
              <option value="2">待收货</option>
              <option value="3">待评论</option>
              <option value="4">退货</option>
              <option value="5">已完成</option>
              <option value="6">已关闭</option>
            </select>
          </div>
          
        </div>
        <div class="search_condition">
          <div class="sltStyle">
            <select name="sltReferee" v-model="searchReferee">
              <option value="">所有店铺</option>
              <option
                :value="item.membercode"
                v-for="(item, index) in classRFList"
                :key="index"
              >
                {{ item.membername }}
              </option>
            </select>
          </div>
          <div class="sltStyle">
            <select name="sltPayStatus" v-model="deliverWeekDay">
              <option value="">正常配送</option>
              <option value="自提">自提</option>
              <option value="周一">周一配送</option>
              <option value="周二">周二配送</option>
              <option value="周三">周三配送</option>
              <option value="周四">周四配送</option>
              <option value="周五">周五配送</option>
              <option value="周六">周六配送</option>
              <option value="周日">周日配送</option>
            </select>
          </div>
          <div class="sltStyle">
            <select
              name="sltProductClass"
              v-model="productFClass"
              @change="setCClass"
            >
              <option value="">请选择商品类别</option>
              <option
                :value="item.fcid"
                v-for="(item, index) in classFList"
                :key="index"
              >
                {{ item.fcname }}
              </option>
            </select>
            <select name="sltProductCClass" v-model="productCClass" @change="getCClassName">
              <option value="">请选择二级分类</option>
              <option
                :value="item.ccid"
                v-for="(item, index) in classCList"
                :key="index"
              >
                {{ item.ccname }}
              </option>
            </select>
          </div>
          <div class="cname">
            <label for="txtContractName" class="control-label">联系人</label>
          </div>

          <div class="cname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractName"
              name="txtContractName"
              v-model.trim="searchpcontact"
              @keyup.enter="search"
            />
          </div>
          <div class="sltStyle" style="padding-top: 2px; padding-left: 10px">
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button class="btn btn-default" type="button" @click="search">
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;按下单搜索
            </button>
            <button class="btn btn-default" type="button" @click="searchD">
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;按配送搜索
            </button>
          </div>
        </div>
        <div class="search_condition">
        <!-- <div class="col-sm-6 btnexport"> -->
          <div style="padding-right: 20px;white-space:nowrap;">
            共选择了{{ selectedordernum }}个订单
          </div>
          <button class="btn btn-default" @click="checkAllList">
            <i class="fa fa-check-square-o" aria-hidden="true"></i
            >&nbsp;一键全选
          </button>
          <button class="btn btn-default" @click="cancelAllList">
            <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;一键取消
          </button>
        <!-- </div>
        <div class="col-sm-3 btnexport"> -->
            <div>
            <label class="control-label">配送日期</label>
          </div>
            <input
              type="text"
              class="form-control1"
              style="width:100px;"
              id="txtAllocateDate"
              name="txtAllocateDate"
              readonly
              @click.stop="showcalender($event, 'a')"
              v-model.trim="allocateDate"
            />
          
          <button class="btn btn-default" @click="fetchData">
            <i class="fa fa-print" aria-hidden="true"></i
            >&nbsp;打印出库总单/配货单
          </button>
        <!-- </div>
        <div class="col-sm-3 btnexport"> -->
        <download-excel
              class="btn btn-default"
              :fetch="fetchDataR"
              :fields="jsonFieldsR"
              :name="filenameR"
            >
              <i class="fa fa-table" aria-hidden="true"></i>&nbsp;导出分类订货单
            </download-excel>
        <!-- </div>
        <div class="col-sm-2 btnexport"> -->
          <button class="btn btn-default" @click="fetchDataZD">
            <i class="fa fa-print" aria-hidden="true"></i>&nbsp;仅打印出库总单
          </button>
        <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="orderlist">
      <div
        class="order_item"
        v-for="(item, index) in currentpagelist"
        :key="index"
      >
        <div class="order_item_briefinfo">
          <div class="order_item_briefinfo_orderchk">
            <input
              type="checkbox"
              :checked="item.checked"
              @change="handItemChangeC(item.orderid)"
            />
          </div>
          <div class="order_item_briefinfo_ordernum">
            订单编号：{{ item.ordersno }}
          </div>
          <div class="order_item_briefinfo_orderdate">
            下单时间：{{ item.orderdatetime }}
            <label style="color: red">{{
              item.newflag == 1 ? " 新订单" : ""
            }}</label>
          </div>
          <div class="order_item_briefinfo_orderpay" v-if="item.membername">
            店铺：{{ item.membername }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            支付方式：{{ item.paytype }}
          </div>
          <div class="order_item_briefinfo_orderpay">
            订单状态：{{ item.orderstatus }}
          </div>
        </div>
        <div class="order_item_content">
          <div class="order_item_content_product">
            <div class="productinfo_img">
              <img :src="item.plist[0].imgurl" />
            </div>

            <div class="productinfo">
              <div class="productinfo_title">{{ item.plist[0].pname }}</div>
              <div class="productinfo_guige">
                规格：{{ item.plist[0].pguige }}
              </div>
              <div class="productinfo_num">
                数量：{{ item.plist[0].wscount }}
              </div>
              <div class="productinfo_price">
                商品总价：C${{ item.plist[0].wsallprice }}
              </div>
            </div>
          </div>
          <div class="order_item_content_order">
            <div class="orderprice">
              <div v-if="item.icard_name" style="color: red">
                {{ item.icard_name }}配送
              </div>
              <div v-if="item.boxsn" style="color: #00bfff">
                配送：{{ item.boxsn }}
              </div>
              <div>C${{ item.ordertotalprice }}</div>
            </div>
            <div class="ordercontact" v-if="item.deliverytype=='1'">
              <div class="ordercontact_name">
                联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{ item.contactname }}（{{
                  item.userid
                }}）
              </div>
              <div class="ordercontact_tel">联系电话：{{ item.mobile }}</div>
              <div class="ordercontact_address">
                <div class="address_title">配送地址：</div>
                <div class="address_content" >{{ item.address }}</div>
              </div>
            </div>
            <div class="ordercontact" v-else>
              <div class="ordercontact_name">
                联&nbsp;&nbsp;系&nbsp;&nbsp;人：{{ item.pickupName }}（{{
                  item.userid
                }}）
              </div>
              <div class="ordercontact_tel">联系电话：{{ item.pickupTel }}</div>
              <div class="ordercontact_address">
                <div class="address_title" style="color:red;">自提地点：</div>
                <div class="address_content" >{{ item.pickupCity }}</div>
              </div>
            </div>
            <div class="orderremark">{{ item.remarks }}</div>
          </div>
          <div class="order_item_content_opr">
            <button
              class="btn btn-default"
              type="button"
              @click="viewOrderDetail(item.orderid)"
            >
              <i class="fa fa-newspaper-o" aria-hidden="true"></i>&nbsp;订单详情
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="editOrder(item.orderid)"
              :disabled="
                item.orderstatus === '订单关闭' || item.orderstatus === '已取消'
                  ? true
                  : false
              "
            >
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i
              >&nbsp;编辑订单
            </button>
            <button
              class="btn btn-default"
              type="button"
              @click="payOrder(item.orderid)"
              v-if="item.orderstatus === '待付款'"
            >
              <i class="fa fa-money" aria-hidden="true"></i>&nbsp;确认收款
            </button>
            <!-- <button
              class="btn btn-default"
              type="button"
              @click="payOrder(item.orderid)"
              v-if="item.orderstatus === '待发货'"
            >
              <i class="fa fa-truck" aria-hidden="true"></i>&nbsp;提交发货
            </button> -->
          </div>
        </div>
      </div>
      <div class="noresult" v-if="shownoresult">
        没有找到符合查询条件的订单！
      </div>
    </div>

    <div class="pagenum_info" v-if="!shownoresult">
      <span class="btmInfo" style="vertical-align: middle"
        >&nbsp;&nbsp;共有 {{ recordcount }} 条记录，当前第 {{ currentpage }}/{{
          pagecount
        }}
        页</span
      >
      <div style="float: right; padding-right: 30px">
        <button class="btn btn-default" type="button" @click="getNewPage(1)">
          首页
        </button>
        <button
          class="btn btn-default"
          type="button"
          @click="getNewPage(currentpage - 1)"
        >
          上页
        </button>
        <button
          class="btn btn-default"
          type="button"
          @click="getNewPage(currentpage + 1)"
        >
          下页
        </button>
        <button
          class="btn btn-default"
          type="button"
          @click="getNewPage(pagecount)"
        >
          尾页
        </button>
        <span class="btmInfo" style="margin-right: 2px">跳转到</span>
        <input
          type="text"
          size="1"
          id="txtGotoPage"
          autocomplete="off"
          v-int-only
          style="margin-right: 10px; font-size: 0.9em"
          v-model.trim="gotopage"
        />
        <button
          type="button"
          class="btn btn-default"
          @click="getNewPage(gotopage)"
        >
          跳转
        </button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import Toast from "../../components/Toast";
import vueEasyPrint from "vue-easy-print";
import printList from "./printList";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "newOrderList",
  components: {
    Calender,
    Toast,
    "download-excel":JsonExcel,
    vueEasyPrint,
    printList,
  },
  data() {
    return {
      productFClass: "",
      productCClass: "",
      productCName: "",
      deliverWeekDay: "",
      classFList: [],
      classCList: [],
      classRFList: [],
      searchReferee: "",
      nowtime: "",
      selectedordernum: 0,
      orderStatus: "",
      orderStatusExcept:"",
      exportList: [],
      // jsonFields: {  //导出Excel表格的表头设置
      //     '商品名称': 'pname',
      //     '规格': 'psize',
      //     '数量': 'psum',
      //     '金额': 'pprice',
      //     '商品类别': 'pclassname'
      // },
      jsonFieldsR: {  //导出Excel表格的表头设置
            '联系人':'rname',
            '联系电话': 'rtel',
            '商品': 'pname',
            '规格': 'size',
            '数量': 'pcount',
            '总价': 'pprice',
            '商品类别':'pclassname'
        },
        filenameR:'',
      listhours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
      listsecond: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      // filename:this.GLOBAL.getToday() + '出库总单.xls',
      allocateDate:this.GLOBAL.getToday(),
      searchedate: "",
      searchbdate: "",
      searchedatef: "",
      searchbdatef: "",
      shownoresult: false,
      orderList: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpcontact: "",
      searchpname: "",
      isshowcalender: false,
      show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS: "",
      sEHour: "00",
      sEMinute: "00",
      sESecond: "00",
      sBHour: "00",
      sBMinute: "00",
      sBSecond: "00",
      sheetdata: [],
      exportinfo: [],
    };
  },
  watch: {
    nowtime() {
      this.refreshorder();
    },
  },
  methods: {
    async fetchDataR(){
      
      let vflag = false
      let idlist='0'
      
      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist=idlist+','+v.orderid
          vflag = true
        }
      })
      
      if(vflag){

        if(this.productFClass==''){
          this.$refs.toast.toast("请选择商品类别")
          return false
        }else{
          this.filenameR=this.GLOBAL.getToday() + this.productCName+'订货单.xls'
        }
        const res = await this.$get(this.GLOBAL.serverAddress + "/exportProductListExcelR/", 
        {'idlist':idlist,'fclassid':this.productFClass,'cclassid':this.productCClass});
        console.log(res.data);
          
        if (res.status === 200) {
          if(res.data.length > 0){
            setTimeout(()=>{
              this.$router.go(0)
            },1500)  
            return res.data
            // this.exportList=res.data
          }else{
            this.$refs.toast.toast("未查到相关订单")
            return false
          }
        }
      }else{
        this.$refs.toast.toast("请选择订单")
        return false
      }
      
      
    },
    refreshorder() {
      return setTimeout(() => {
        this.clearSearch();
        this.search();
        // this.mounted()
        // console.log("刷新一次")
      }, 100000);
    },
    // printDemo(row){
    //   // this.sheetdata={"name":"test","age":"13"}
    //   this.$refs.easyPrint.print()
    // },
    handItemChangeC(orderid) {
      let tmpList = this.orderList;
      let findex = tmpList.findIndex((v) => v.orderid === orderid);

      tmpList[findex].checked = !tmpList[findex].checked;

      this.orderList = tmpList;

      let i = 0;
      this.orderList.forEach((v) => {
        if (v.checked) {
          i = i + 1;
        }
      });
      this.selectedordernum = i;

      // console.log(this.menulist)
    },
    // printOrder(){

    //   let tmpDom=this.$refs.print

    //   console.log(tmpDom)

    //   this.$print(tmpDom)
    // },
    viewOrderDetail(id) {
      this.$router.push("/retailorderdetail?from=1&id=" + id+"&page="+this.currentpage);
    },
    editOrder(id) {
      this.$router.push("/updateretailorder?from=1&id=" + id+"&page="+this.currentpage);
    },
    payOrder(id) {
      if (confirm("您确认已经收到了该订单的款项吗？确认后无法撤回。")) {
        axios({
          method: "put",
          data: { id: id, status: "1" },
          url: this.GLOBAL.serverAddress + "/updateRetailOrderStatus/",
        }).then(
          (response) => {
            this.clearSearch();
            this.search();
            alert("订单状态更新完成。");
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },
    setCClass() {
      this.classFList.forEach((v) => {
        if (v.fcid == this.productFClass) {
          this.productCClass = "";
          this.productCName=v.fcname;
          // this.classCList=""
          this.classCList = v.cclass;
        }
      });
      // alert(this.productFClass)
    },
    getCClassName() {
      this.classCList.forEach((v) => {
        if (v.fcid == this.productCClass) {
          // this.productCClass = "";
          this.productCName=v.fcname;
          // this.classCList=""
          // this.classCList = v.cclass;
        }
      });
      // alert(this.productFClass)
    },
    deliveryOrder(id) {
      if (confirm("您确认该订单已取货吗吗？确认后无法撤回。")) {
        axios({
          method: "put",
          data: { id: id, status: "已取货", type: "2" },
          url: this.GLOBAL.serverAddress + "/222updateOrderStatus/",
        }).then(
          (response) => {
            this.clearSearch();
            this.search();
            alert("订单状态更新完成。");
          },
          (error) => {
            alert(error.message);
            return false;
          }
        );
      }
    },
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
      //   this.searchyxqdate = data;
      // console.log(this.currentDateS)
      if (this.currentDateS === "b") {
        this.searchbdate = data;
      }else if (this.currentDateS === "a") {
        this.allocateDate = data;
      } else {
        this.searchedate = data;
      }
      this.isshowcalender = false;
    },
    showcalender(e, v) {
      var divC = this.$refs.curDiv;
      this.currentDateS = v;
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    clearSearch() {
      this.deliverWeekDay = "";
      this.searchpname = "";
      this.searchpcontact = "";
      this.searchReferee = "";
      this.productFClass = "";
      this.productCClass = "";
      this.productCName = "";
      this.filenameR="";
      this.searchedate = "";
      this.searchbdate = "";
      this.orderStatus = "";
      this.orderStatusExcept = "";
      this.sEHour = "00";
      this.sEMinute = "00";
      this.sESecond = "00";
      this.sBHour = "00";
      this.sBMinute = "00";
      this.sBSecond = "00";
      this.cancelAllList();
    },
    checkAllList() {
      // this.orderList.forEach((v) => {
      //   v.checked = true;
      // });
      // this.selectedordernum = this.recordcount;

      this.currentpagelist.forEach((v) => {

        let findex = this.orderList.findIndex((v2) => v2.orderid === v.orderid);

        this.orderList[findex].checked = true;
      })
      this.selectedordernum=0
      this.orderList.forEach((v) => {
        if(v.checked){
          this.selectedordernum+=1
        }
      });

    },
    cancelAllList() {
      this.orderList.forEach((v) => {
        v.checked = false;
      });
      this.selectedordernum = 0;
    },
    async fetchData() {
      let vflag = false;
      let idlist = "0";
      if(this.allocateDate==""){
        this.$refs.toast.toast("请选择配送日期");
        return false;
      }
      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist = idlist + "," + v.orderid;
          vflag = true;
        }
      });

      // if(this.searchbdate!=''){
      //   this.searchbdatef=this.searchbdate + ' ' + this.sBHour + ':' + this.sBMinute + ':' + this.sBSecond
      // }else{
      //   this.searchbdatef=''
      // }
      // if(this.searchedate!=''){
      //   this.searchedatef=this.searchedate + ' ' + this.sEHour + ':' + this.sEMinute + ':' + this.sESecond
      // }else{
      //   this.searchedatef=''
      // }
      if (vflag) {
        const res = await this.$get(
          this.GLOBAL.serverAddress + "/exportPHDExcel/",
          { 'idlist': idlist,'allocatedate':this.allocateDate }
        );
        // console.log(res.data[0]);

        if (res.status === 200) {
          if (res.data.length > 0) {
            this.sheetdata = res.data[0];
            this.exportinfo = res.data[1];
            setTimeout(() => {
              this.$refs.easyPrint.print();
            }, 1500);
            // this.printDemo(0)
            // return res.data
            // this.exportList=res.data
          } else {
            this.$refs.toast.toast("未查到相关订单");
            return false;
          }
        }
      } else {
        this.$refs.toast.toast("请选择订单");
        return false;
      }
    },
    async fetchDataZD() {
      let vflag = false;
      let idlist = "0";

      this.orderList.forEach((v) => {
        if (v.checked) {
          idlist = idlist + "," + v.orderid;
          vflag = true;
        }
      });

      if (vflag) {
        const res = await this.$get(
          this.GLOBAL.serverAddress + "/exportPHDExcelZD/",
          { idlist: idlist }
        );
        // console.log(res.data[0]);

        if (res.status === 200) {
          if (res.data.length > 0) {
            this.sheetdata = res.data[0];
            this.exportinfo = res.data[1];
            setTimeout(() => {
              this.$refs.easyPrint.print();
            }, 1500);
            // this.printDemo(0)
            // return res.data
            // this.exportList=res.data
          } else {
            this.$refs.toast.toast("未查到相关订单");
            return false;
          }
        }
      } else {
        this.$refs.toast.toast("请选择订单");
        return false;
      }
    },
    getNewPage(pagenum) {

      if (pagenum >= 1 && pagenum <= this.pagecount) {
        this.currentpage = pagenum;
      }
    },
    async getAllDMember() {
      const res2 = await this.$get(
        this.GLOBAL.serverAddress + "/getAllDMember/",
        {}
      );
      console.log(res2);

      if (res2.status === 200) {
        this.classRFList = res2.data;
      }
    },
    async getAllClass(){
      const res3 = await this.$get(
        this.GLOBAL.serverAddress + "/getAllProductClass/",
        {}
      );
      // console.log(res2);

      if (res3.status === 200) {
        this.classFList = res3.data;
      }
    },
    async searchD() {
      this.currentpage = 1;
      this.selectedordernum = 0;
      let tmpClass = "";
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/searchRetailOrderByDeliveryDate/",
        {
          
          allocateDate: this.allocateDate,
          scope:"1"
        }
      );
      // console.log(res);

      if (res.status === 200) {
        this.orderList = res.data;
        if (this.orderList.length > 0) {
          this.shownoresult = false;
        } else {
          this.shownoresult = true;
        }
      }
    },
    async search() {
      this.currentpage = 1;
      this.selectedordernum = 0;
      let tmpClass = "";
      if (this.productCClass != "") {
        tmpClass = this.productCClass;
      } else {
        tmpClass = this.productFClass;
      }

      if (this.searchbdate != "") {
        this.searchbdatef =
          this.searchbdate +
          " " +
          this.sBHour +
          ":" +
          this.sBMinute +
          ":" +
          this.sBSecond;
      } else {
        this.searchbdatef = "";
      }
      if (this.searchedate != "") {
        this.searchedatef =
          this.searchedate +
          " " +
          this.sEHour +
          ":" +
          this.sEMinute +
          ":" +
          this.sESecond;
      } else {
        this.searchedatef = "";
      }
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/searchRetailOrder/",
        {
          pname: this.searchpname,
          pclass: tmpClass,
          contact: this.searchpcontact,
          bdate: this.searchbdatef,
          edate: this.searchedatef,
          status: this.orderStatus,
          statusexcept:this.orderStatusExcept,
          membercode: this.searchReferee,
          delweedDay: this.deliverWeekDay,
          scope:"1"
        }
      );
      // console.log(res);

      if (res.status === 200) {
        this.orderList = res.data;
        if (this.orderList.length > 0) {
          this.shownoresult = false;
        } else {
          this.shownoresult = true;
        }
      }
      // const res2 = await this.$get(
      //   this.GLOBAL.serverAddress + "/getAllDMember/",
      //   {}
      // );
      // console.log(res2);

      // if (res2.status === 200) {
      //   this.classRFList = res2.data;
      // }

      // const res3 = await this.$get(
      //   this.GLOBAL.serverAddress + "/getAllProductClass/",
      //   {}
      // );
      // // console.log(res2);

      // if (res3.status === 200) {
      //   this.classFList = res3.data;
      // }

      this.nowtime = new Date();
    },
  },
  computed: {
    currentpagelist() {
      return this.pages[this.currentpage - 1];
    },
    recordcount() {
      return this.orderList.length;
    },
    pagecount() {
      return Math.ceil(this.orderList.length / 10);
    },
    pages() {
      const pages = [];
      this.orderList.forEach((item, index) => {
        const page = Math.floor(index / 10);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
  },
  mounted() {
    
    this.haveRights("|0501|");
    this.getAllDMember()
    this.getAllClass()
    this.search();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
    if(this.$route.params.page)
    {
      this.currentpage=parseInt(this.$route.params.page)
      // console.log(this.pagecount)
      // this.getNewPage(2)
    }
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
  destroy() {
    clearTimeout(this.refreshorder);
  },
};
</script>

<style scoped lang="less">
div{
  touch-action: pan-y;
}

.btnexport {
  padding-top: 10px;
  padding-left: 50px;
  display: flex;
  align-items: center;
}
.printList {
  position: absolute;
  left: 300px;
}
.bdate_value_time {
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition_f{
  display: flex;
  flex-direction: column;

  .search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content: space-around;
    align-items: center;
    text-align: right;
    padding: 10px;
    .pname {
      //   display: flex;
      flex: 1;
      padding-right: 5px;
    }
    .sltStyle {
      flex: 1;
      padding-right: 20px;
      color: #999;
      display: flex;
      select {
        border: 1px solid #e9e9e9;
        font-size: 0.9em;
        outline: none;
        padding: 0.5em 1em;
        color: #999;
      }
    }
    .pname_value {
      flex: 2;
      
    }

    .cname {
      flex: 1;
      padding-right: 5px;
      white-space: nowrap;
    }

    .cname_value {
      flex: 1;
      // border: 1px solid red;
    }

    .bdate {
      flex: 1;
      padding-right: 5px;
      white-space: nowrap;
    }

    .bdate_value {
      flex: 1;
    }

    .edate {
      //   border: 1px solid green;
      flex: 1;
      text-align: center;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }

    .edate_value {
      flex: 1;
    }
  }
}

.orderlist {
  color: #9c9c9c;

  // padding:10px;
  .order_item {
    //   width:100%;
    margin-top: 15px;
    background-color: #fff;
    // padding-top:15px;
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_orderchk {
        input{
          // outline: 1px solid red;
          // border:0px solid black;
          transform:scale(1.8, 1.8);
        }
      }
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 2;
      }

      .order_item_briefinfo_orderdate {
        flex: 2;
        white-space: nowrap;
        word-break: none;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            max-height: 100px;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 5;
        display: flex;
        .orderremark {
          flex: 2;
          padding: 5px;
          display: flex;
          align-items: center;
          color: #ff4500;
        }
        .orderprice {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        // .orderstatus {
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info {
  background: #fff;
  height: 50px;
  margin-top: 20px;
  padding: 10px;
  color: #6c757d;
  // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>